@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


ion-title {
    @apply text-xl;
}
ion-card-title {
    @apply text-2xl font-bold;
}

.card-content-md p,
.card-content-ios p {
    @apply text-base;
}

ion-item-divider {
    --inner-padding-end: 0px;
}

app-onboarding ion-content {
    --background: theme("colors.white");
}
app-verification ion-content {
    --background: theme("colors.white");
}

utoolbar {
    margin-top: theme("margin.12");
}
