// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #df1583;
    --ion-color-primary-rgb: 223, 21, 131;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #c41273;
    --ion-color-primary-tint: #e22c8f;

    --ion-color-secondary: #ffffff;
    --ion-color-secondary-rgb: 255, 255, 255;
    --ion-color-secondary-contrast: #df1583;
    --ion-color-secondary-contrast-rgb: 100, 100, 100;
    --ion-color-secondary-shade: #e0e0e0;
    --ion-color-secondary-tint: #ffffff;

    /** success **/
    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;

    /** warning **/
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    /** danger **/
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** Custom Ionic **/
    --ion-color-step-600: var(--ion-color-medium);
    --ion-background-color: var(--ion-color-light);
    --ion-item-background: #fff;
    --ion-item-border-color-rgb: var(--ion-color-dark-rgb);
    --ion-color-shade: var(--ion-color-dark);

    --ion-font-family: "Lato";
}

.ion-color-white {
    --ion-color-base: #fff;
    --ion-color-base-rgb: 255, 255, 255;
    --ion-color-contrast: #000000;
    --ion-color-contrast-rgb: 0, 0, 0;
    --ion-color-shade: #d7d8da;
    --ion-color-tint: #f5f6f9;
}
/* lato-300 - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    src: url("/assets/fonts/lato-v16-latin-300.eot"); /* IE9 Compat Modes */
    src: local("Lato Light"), local("Lato-Light"), url("/assets/fonts/lato-v16-latin-300.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/lato-v16-latin-300.woff2") format("woff2"), /* Super Modern Browsers */ url("/assets/fonts/lato-v16-latin-300.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/lato-v16-latin-300.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/lato-v16-latin-300.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/lato-v16-latin-regular.eot"); /* IE9 Compat Modes */
    src: local("Lato Regular"), local("Lato-Regular"), url("/assets/fonts/lato-v16-latin-regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/lato-v16-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */ url("/assets/fonts/lato-v16-latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/lato-v16-latin-regular.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/lato-v16-latin-regular.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: url("/assets/fonts/lato-v16-latin-700.eot"); /* IE9 Compat Modes */
    src: local("Lato Bold"), local("Lato-Bold"), url("/assets/fonts/lato-v16-latin-700.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/lato-v16-latin-700.woff2") format("woff2"), /* Super Modern Browsers */ url("/assets/fonts/lato-v16-latin-700.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/lato-v16-latin-700.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/lato-v16-latin-700.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    src: url("/assets/fonts/lato-v16-latin-900.eot"); /* IE9 Compat Modes */
    src: local("Lato Black"), local("Lato-Black"), url("/assets/fonts/lato-v16-latin-900.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/lato-v16-latin-900.woff2") format("woff2"), /* Super Modern Browsers */ url("/assets/fonts/lato-v16-latin-900.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/lato-v16-latin-900.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/lato-v16-latin-900.svg#Lato") format("svg"); /* Legacy iOS */
}
