/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05) !important;
    border-radius: 8px !important;
    margin: 16px !important;
    --color: var(--ion-color) !important;
    user-select: text;
}

ion-menu {
    --width: 320px;
    --min-width: 320px;
}

[required],
ion-item.required {
    ion-label {
        font-weight: 500;
        &:after {
            content: "*";
            color: var(--ion-color-primary);
            margin-left: 5px;
        }
    }
}

ion-toolbar {
    --min-height: 55px;
}
ion-card-subtitle {
    --color: var(--ion-color-medium);
}

ion-segment-button {
    ion-label {
        text-transform: initial;
        font-size: 16px;
        letter-spacing: 0px;
    }
}

ion-tab-bar {
    height: 60px;
}

ion-item {
    --border-color: var(--ion-color-light-shade);
}

ion-tab-button {
    ion-label {
        color: var(--ion-color-medium);
    }
    svg {
        font-size: 2.3em !important;
    }
    ion-badge {
        top: 10px;

        left: calc(50% + 18px);
    }
    &.tab-selected {
        ion-label {
            color: var(--ion-color-primary);
        }
    }
}

app-onboarding {
    ion-footer {
        ion-toolbar {
            --border-color: transparent;
            --background: #f4f5f8;
        }
    }
}
ion-button.withBadge::part(native) {
    overflow: visible;
}
